
// Init Mmenu-light and menu toggler
function initMmenuLight() {
  var menu = new MmenuLight(
      document.querySelector('#menu'),
      '(max-width: 899px)'
  );

  var navi = menu.navigation();
  var drawer = menu.offcanvas();

  document.querySelector('a.menu-toggler')
          .addEventListener('click', function(e) {
              e.preventDefault();
              var isOpened = this.classList.contains('opened');
              isOpened ? drawer.close() : drawer.open();
              this.classList.toggle('opened');
          });

  // Toggle menu-toggler "opened" class when menu is closed
  document.querySelector('.mm-ocd__backdrop')
          .addEventListener('click', function(e) {
            document.querySelector('a.menu-toggler').classList.toggle('opened');
          });
}

// Append Footer menu to main menu
function appendFooterMenu2MainMenu(){
  document.querySelectorAll('footer li').forEach(function(elem){
    var el = elem.cloneNode(true);
    elem.classList.add('mobile-only');
    document.querySelector('#menu ul').innerHTML += elem.outerHTML;
  });
}

function init(){
  appendFooterMenu2MainMenu();
  initMmenuLight();
}
document.addEventListener("DOMContentLoaded", init);
