var map;
var zones;
var couleur = 'blue';

function initMap() {

  setup();
  generateTileLayers();

  generateZonesLayer();

  fitMap();
  window.addEventListener("resize", fitMap);
}
//document.addEventListener("DOMContentLoaded", initMap);
window.addEventListener('load', initMap);

function setup(){
  // Define "couleur"
  var cssVars = getComputedStyle(document.body);
  if(marche == 'terres') couleur = cssVars.getPropertyValue('--couleur-terres');
  if(marche == 'vignes') couleur = cssVars.getPropertyValue('--couleur-vignes');
  if(marche == 'forets') couleur = cssVars.getPropertyValue('--couleur-forets');
  if(marche == 'maisons') couleur = cssVars.getPropertyValue('--couleur-maisons');

  // Define map
  map = L.map('lpdt-map', {
    //center: [47.330177, -1.621102],
    //zoom: 5,
    scrollWheelZoom: false,
    zoomSnap: 0.25
  });

  // Move controls layer
  map.on('load', function(e){
    var controls_layer = document.querySelector('.leaflet-control-layers-base');
    document.querySelector('.custom-controls-right').append(controls_layer);
  });

  // Define Zones layer
  zones = L.featureGroup();

  // Custom JS
  document.querySelectorAll('.custom-controls-right > a').forEach(function(item,i){
    item.addEventListener('click', function(e){
      item.nextSibling.nextSibling.classList.add('opened');
    });
  });
  document.querySelectorAll('.custom-controls-right  span.close').forEach(function(item,i){
    item.addEventListener('click', function(e){
      document.querySelector('.custom-controls-right .opened').classList.remove('opened')
    });
  });
}

function generateTileLayers() {
		var CartesIGN = L.tileLayer('https://wxs.ign.fr/{ignApiKey}/wmts?LAYER={ignLayer}&EXCEPTIONS=text/xml&FORMAT={format}&SERVICE={service}&VERSION=1.0.0&REQUEST=GetTile&STYLE={style}&TILEMATRIXSET=PM&&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}', {
  		ignApiKey: 'fta9s54vfgwkqsm00l9p41dm',
  		ignLayer: 'GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN-EXPRESS.NIVEAUXGRIS',
  		style: 'normal',
  		format: 'image/png',
  		service: 'WMTS',
  		attribution: '&copy; <a href="http://www.ign.fr/">IGN</a>'
		});


		// IGN Ortho
		var ImageAerienne = L.tileLayer('https://wxs.ign.fr/{ignApiKey}/wmts?LAYER={ignLayer}&EXCEPTIONS=text/xml&FORMAT={format}&SERVICE={service}&VERSION=1.0.0&REQUEST=GetTile&STYLE={style}&TILEMATRIXSET=PM&&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}', {
  		ignApiKey: 'choisirgeoportail',
  		ignLayer: 'ORTHOIMAGERY.ORTHOPHOTOS',
  		style: 'normal',
  		format: 'image/jpeg',
  		service: 'WMTS',
  		attribution: '&copy; <a href="http://www.ign.fr/">IGN</a>'
		});

    var OpenStreetMap_Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    	maxZoom: 19,
    	attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });

		var baseLayers = {
			//"Cartes IGN": CartesIGN,
			'<span class="satellite">Satellite</span>' : ImageAerienne,
			'<span class="plan">Plan IGN</span>' : OpenStreetMap_Mapnik // TODO: replace by Cartes IGN with correct API key
		};

		//L.control.layers(baseLayers, overlays).addTo(map);
		L.control.layers(baseLayers).addTo(map);

		ImageAerienne.addTo(map);
		// CartesIGN.getContainer().className+=" styleMyLayer";

	}

function generateZonesLayer() {
  //console.log(JSON.stringify(geodatas));
  geodatas.forEach(function(item,i){
    var geom = JSON.parse(item.geom);
    L.geoJSON(geom, {
        onEachFeature: onEachFeatureClosure(item),
        style: function (feature) {

          return {
            weight: 1,
            color: '#fff',
            fillColor: couleur,
            fillOpacity: .7
          };
        }
    })
    .addTo(zones);
  });

  zones.addTo(map);
}

function onEachFeatureClosure(item){

  return function onEachFeature(feature, layer) {
    layer.bindPopup(getPopup(item));

    layer.on('mouseover', function(e){
      this.setStyle({
        fillColor: '#fff',
        fillOpacity: 1
      });

      var center = this.getBounds().getCenter();
      var lng = this.getBounds().getCenter().lng;
      var lat = this.getBounds()._southWest.lat;
      this.openPopup(L.latLng(lat,lng));
      //this.openPopup(e.latlng);
    });
    layer.on('mouseout', function(e){
      if(!this.isPopupOpen()){
        this.setStyle({
          fillColor: couleur,
          fillOpacity: .7
        });
        this.closePopup();
      }
    });
    layer.on('popupclose', function(e){
      this.setStyle({
        fillColor: couleur,
        fillOpacity: .7
      });
    });
    layer.on('click', function(e){
      window.location = 'carte.php?marche='+marche+'&reg='+item.id;
    });
  }

}

function getPopup(item){

  var content = `
  <div class="info_window">
    <section class="card">
      <div class="card_content">
        <h2 class="card_title">`+item.libelle+`</h2>
        <div class="card_subtitle">Prix moyen des terres et prés <strong>en 2018</strong></div>

        <!--
        ///  BADGES PRIX \\\ -->

        <!-- Terres -->
        <div class="badge prix_terres_libres">
          <div class="badge_title">Libres non bâtis</div>
          <div class="badge_value"><span>3 780</span> €/ha</div>
          <div class="badge_notice"><span class="stable">Stable</span> par rapport à 2017</div>
        </div>

        <div class="badge prix_terres_loues">
          <div class="badge_title">Loués non bâtis</div>
          <div class="badge_value"><span>3 040</span> €/ha</div>
          <div class="badge_notice"><span class="up">+ 4%</span> par rapport à 2017</div>
        </div>

      </div>
    </section>
  </div>
  `;
  return L.popup({
    offset: L.point(0,175)
  }).setContent(content);
};

function fitMap(){
  var bounds = zones.getBounds();

  // Offset the map to the right to let the "infos" live
  var coveredLng = bounds._northEast.lng - bounds._southWest.lng;
  var windowW = window.innerWidth;
  var columnW = document.querySelector('.carte_infos').offsetWidth;
  var append = (windowW > 900) ? columnW : 0;
  var ratio = ( coveredLng * windowW ) / ( windowW - append );
  bounds._southWest.lng = bounds._northEast.lng - ratio;

  map.fitBounds(bounds);
}
